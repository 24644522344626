const multilevelAccordion = () => {
    const PARAMS = {
        accordion     : '.js-ml-accordion',
        button        : '.js-ml-accordion-button',
        body          : '.js-ml-accordion-body',
        expandedClass : '_expanded',
        hiddenClass   : '_hide',
        collapseLevel(level) {
            const lvl = $(`[data-level="${level}"]`);
            const lvls = lvl.find(PARAMS.accordion);
            const body = lvl.find(PARAMS.body);

            lvl.removeClass(PARAMS.expandedClass);
            lvls.removeClass(PARAMS.expandedClass);
            body.stop().slideUp(300);
        },
        toggleLevel(element) {
            const parent = element.closest(PARAMS.accordion);
            const id     = element.attr('data-accordion-id');
            const level  = element.attr('data-level-collapser');
            const body   = $(`[data-accordion-level="${id}"]`);

            if ( !parent.hasClass(PARAMS.expandedClass) ) {
                //collapse all other accordions of the same level
                PARAMS.collapseLevel(level);

                parent.addClass(PARAMS.expandedClass);
                body.stop().slideDown(300);
            }
            else {
                parent.removeClass(PARAMS.expandedClass);
                body.stop().slideUp(300);
            }
        }
    };

    $(PARAMS.accordion).each(function(){
        if (!$(this).hasClass(PARAMS.expandedClass)) {
            $(this).find(PARAMS.body).removeClass(PARAMS.hiddenClass).hide();
        }
        else {
            $(this).find(PARAMS.body).removeClass(PARAMS.hiddenClass);
        }
    });
    $(document).on('click', PARAMS.button, function(){
        PARAMS.toggleLevel($(this));
    });
};