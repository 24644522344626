const formValidator = () => {

    const app = $('.js-form');
    window.isValidPhone = false;

    if ( app.length ) {
        $.validator.setDefaults({
            debug   : true,
            success : 'valid'
        });
        $.validator.addMethod("validateEmail", function(value, element) {
            if (/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(value)) {
                return true;
            } else {
                return false;
            }
        });
        $.validator.addMethod("validatePhone", function (value, element) {
            return value.replace(/\D+/g, '').length > 10;
            //return window.isValidPhone;
        });
        $.validator.addMethod("validateBirthDate", function (value, element) {
            return value.replace(/\D+/g, '').length === 8;
        });
        $.validator.addMethod("validatePassport", function (value, element) {
            return value.replace(/\D+/g, '').length === 10; // spaces included
        });
        $.validator.addMethod("validateCyrillic", function (value, element) {
            if (/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ]+$/.test(value)) {
                return true
            }
        });
        $.validator.addMethod("validateDigits", function (value, element) {
            if ( /^\d+$/.test(value) ) {
                return true
            }
        });
        $.validator.addMethod("validateBankBIK", function (value, element) {
            if ( /^\d+$/.test(value) && value.replace(/\D+/g, '').length === 9 ) {
                return true
            }
        });
        $.validator.addMethod("validateBankINN", function (value, element) {
            if ( /^\d+$/.test(value) && value.replace(/\D+/g, '').length === 10 ) {
                return true
            }
        });
        $.validator.addMethod("validateBankKPP", function (value, element) {
            if ( /^\d+$/.test(value) && value.replace(/\D+/g, '').length === 9 ) {
                return true
            }
        });
        $.validator.addMethod("validateBankKS", function (value, element) {
            if ( /^\d+$/.test(value) && value.replace(/\D+/g, '').length === 20 ) {
                return true
            }
        });

        app.each(function(){
            $(this).validate({
                errorClass   : "input--error",
                validClass   : "input--success",
                errorElement : "span",
                rules : {
                    Name : {
                        required : true,
                        validateCyrillic : true,
                    },
                    LastName : {
                        required : true,
                        validateCyrillic : true,
                    },
                    Patronymic : {
                        required : true,
                        validateCyrillic : true,
                    },
                    f_Phone : {
                        required      : true,
                        validatePhone : true
                    },
                    f_PhoneAlt : {
                        required      : true,
                        validatePhone : true
                    },
                    f_Email : {
                        required      : true,
                        validateEmail : true
                    },
                    f_Comment : {
                        required      : true
                    },
                    f_BirthDate  : {
                        required      : true,
                        validateBirthDate : true
                    },
                    birthDate : {
                        required      : true
                    },
                    birthMonth : {
                        required      : true
                    },
                    birthYear : {
                        required      : true
                    },
                    PaymentSum : {
                        required      : true
                    },
                    f_Agreement : {
                        required      : true
                    },
                    Passport : {
                        required         : true,
                        validatePassport : true
                    },
                    CompanyTitle : {
                        required : true,
                    },
                    CompanyName : {
                        required : true,
                        validateCyrillic : true,
                    },
                    CompanyPosition : {
                        required : true,
                    },
                    CompanyReasons : {
                        required : true,
                    },
                    CompanyINN : {
                        required : true,
                        validateBankINN : true
                    },
                    CompanyKPP : {
                        required : true,
                        validateBankKPP : true
                    },
                    CompanyLegalAddress : {
                        required : true,
                    },
                    CompanyFactAddress : {
                        required : true,
                    },
                    CompanyBankAccount : {
                        required : true,
                        validateDigits : true
                    },
                    CompanyBankTitle : {
                        required : true,
                    },
                    CompanyBankBIK : {
                        required : true,
                        validateBankBIK : true
                    },
                    CompanyBankKS : {
                        required : true,
                        validateBankKS : true
                    },
                    CompanyContactName : {
                        required : true,
                        validateCyrillic : true,
                    },
                    CompanyContactPhone : {
                        required : true,
                        validatePhone : true
                    },
                    CompanyContactEmail : {
                        required : true,
                        validateEmail : true
                    },
                },
                messages : {
                    Name           : 'Пожалуйста, напишите имя кириллицей',
                    LastName       : 'Пожалуйста, напишите фамилию кириллицей',
                    Patronymic     : 'Пожалуйста, напишите отчество кириллицей',
                    f_Phone        : 'Проверьте телефон',
                    f_PhoneAlt     : 'Проверьте телефон',
                    f_Email        : 'Проверьте электронную почту',
                    f_Comment      : 'Поле не заполнено',
                    f_BirthDate    : 'Поле не заполнено',
                    birthDate      : 'Поле не заполнено',
                    birthMonth     : 'Поле не заполнено',
                    birthYear      : 'Поле не заполнено',
                    PaymentSum     : 'Поле не заполнено!',
                    f_Agreement    : '',
                    Passport       : 'Поле не заполнено',
                    CompanyTitle    : 'Поле не заполнено',
                    CompanyName     : 'Пожалуйста, напишите Ф.И.О. кириллицей',
                    CompanyPosition : 'Поле не заполнено',
                    CompanyReasons  : 'Поле не заполнено',
                    CompanyINN      : 'Пожалуйста, проверьте ИНН банка',
                    CompanyKPP      : 'Пожалуйста, проверьте КПП банка',
                    CompanyLegalAddress : 'Поле не заполнено',
                    CompanyFactAddress  : 'Поле не заполнено',
                    CompanyBankAccount  : 'Поле не заполнено',
                    CompanyBankTitle    : 'Поле не заполнено',
                    CompanyBankBIK      : 'Пожалуйста, проверьте БИК банка',
                    CompanyBankKS       : 'Пожалуйста, проверьте К/с банка',
                    CompanyContactName  : 'Пожалуйста, напишите Ф.И.О. кириллицей',
                    CompanyContactPhone : 'Проверьте телефон',
                    CompanyContactEmail : 'Проверьте электронную почту',
                },

                errorPlacement: function(error, element) {
                    const parent = $(element).parents('.js-input, .js-custom-input');
                    $(error).appendTo(parent);
                },
                highlight: function(element, errorClass, validClass) {
                    $(element).parents('.js-input, .js-custom-input').addClass(errorClass).removeClass(validClass);
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parents('.js-input, .js-custom-input').removeClass(errorClass).addClass(validClass);
                },
                onfocusout: function(element) {
                    if (!this.checkable(element) && (element.name in this.submitted || !this.optional(element))) {
                        this.element(element);
                    }
                },
                submitHandler: function(form) {
                    const App       = $(form);
                    const action    = App.attr('action');

                    $.ajax({
                        url         : action,
                        type        : 'POST',
                        processData : false,
                        contentType : false,
                        success : function(){},
                        error   : function(){}
                    });
                }
            });
        });
    }
}