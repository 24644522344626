const selectStyler = () => {

    const PARAMS = {
        select    : 'select',
        parent    : '.select',
        initClass : 'select--initialized'
    };

    if ($(PARAMS.parent).length) {
        $(PARAMS.parent).each(function(){
            const $this = $(this);
            const select = $this.find(PARAMS.select);

            if ( !$this.hasClass(PARAMS.initClass) ) {
                select.styler({
                    selectSmartPositioning : false,
                    selectSearch           : false,
                    onFormStyled: function() {
                        $this.addClass(PARAMS.initClass);
                    }
                });
            }
            else {
                select.styler('refresh');
            }
        });

        $(document).on('click', '.select', function(){
            const dropdown = $(this).find('ul');
            if ( !dropdown.hasClass('ps') ) {
                const customScrollBar = new CustomScrollbar(dropdown);
            }
        });
    }

}