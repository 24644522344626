const inputEmail = () => {
    const PARAMS = {
        input : $('.js-input-email')
    };

    if ( PARAMS.input.length ) {
        PARAMS.input.each(function(){
            $(this).inputmask('email', {
                showMaskOnHover : false
            });
        });
    }
}