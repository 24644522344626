const stickyBar = (id, className) => {
    if ( !$(id).length ) return false;

    const PARAMS = {
        element       : $(id),
        offset        : $(id).offset().top,
        height        : $(id).height(),
        stickyClass   : className,
    };

    $(window).on('scroll', function(){
        if( $(window).scrollTop() > PARAMS.offset + PARAMS.height ){
            APP.websiteWrapper.addClass(PARAMS.stickyClass);
        }
        else {
            APP.websiteWrapper.removeClass(PARAMS.stickyClass);
        }
    });
};