const inputDate = () => {
    const PARAMS = {
        input : $('.js-input-date')
    };

    if ( PARAMS.input.length ) {
        PARAMS.input.inputmask('date', {
            mask            : '99.99.9999',
            placeholder     : "дд.мм.гггг",
            showMaskOnHover : false,
        });
    }
}