//example
//robin
//https://habr.com/ru/post/162537/
const autoPhoneMask = () => {
    //window.isValidPhone = false;
    const PARAMS = {
        input    : '.js-input-auto-phone',
        maskOpts : {
            inputmask : {
                showMaskOnHover : false,
                autoUnmask      : true,
                definitions: {
                    '#': {
                        validator: "[0-9]",
                        cardinality: 1
                    }
                },
            },
            match   : /[0-9]/,
            replace : '#',
            list    : $.masksSort($.masksLoad("./assets/phone-codes-world.json"), ['#'], /[0-9]|#/, "mask"),
            listKey : "mask",
            onMaskChange: function(maskObj, completed) {
                const parent = $(this).parents('.js-custom-input');
                const country = parent.find('.js-country');
                let hint;

                if (completed) {
                    hint = maskObj.name_ru;
                    if (maskObj.desc_ru && maskObj.desc_ru != "") {
                        hint += " (" + maskObj.desc_ru + ")";
                    }
                    $(this).attr("placeholder", $(this).inputmask("getemptymask"));
                    country.html(hint);
                    //window.isValidPhone = true;
                }
                else {
                    country.html('');
                    //window.isValidPhone = false;
                    $(this).attr("placeholder", '');
                }
            }
        },
    };
    $(PARAMS.input).inputmasks(PARAMS.maskOpts);
}