const profileFormValidator = () => {

    const app           = $('.js-profile-form');
    const wrapper       = '.js-form-wrapper';
    const invalidClass  = 'form-wrapper--invalid';

    if ( app.length ) {
        $.validator.setDefaults({
            debug   : true,
            success : 'valid'
        });
        $.validator.addMethod("validateBirthDate", function (value, element) {
            return value.replace(/\D+/g, '').length === 8;
        });
        $.validator.addMethod("validateCyrillic", function (value, element) {
            if (/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ]+$/.test(value)) {
                return true
            }
        });

        app.validate({
            errorClass   : "form-wrapper--invalid",
            validClass   : "form-wrapper--valid",
            errorElement : "span",
            groups: {
                profileData: 'profileData',
                identityGroup: 'LastName f_BirthDate'
            },
            rules : {
                LastName : {
                    required : true,
                    validateCyrillic : true,
                    require_from_group: [2, '.js-input-group']
                },
                f_BirthDate  : {
                    required: true,
                    validateBirthDate: true,
                    require_from_group: [2, '.js-input-group']
                },
            },
            messages : {
                LastName     : 'Неверные данные, пожалуйста, попробуйте еще раз',
                f_BirthDate  : '',
            },
            errorPlacement: function(error, element) {
                const parent = $(element).parents(wrapper);
                if ( $(element).hasClass('js-input-group') && !$('span.form-wrapper--invalid').length ) {
                    $(error).appendTo(parent);
                }
            },
            highlight: function(element, errorClass, validClass) {
                $(element).parents(wrapper).addClass(errorClass).removeClass(validClass);
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).parents(wrapper).removeClass(errorClass).addClass(validClass);
            },
            onfocusout: function(element) {
                if (!this.checkable(element) && (element.name in this.submitted || !this.optional(element))) {
                    this.element(element);
                }
            },
            submitHandler: function(form) {
                const App       = $(form);
                const action    = App.attr('action');

                $.ajax({
                    url         : action,
                    type        : 'POST',
                    processData : false,
                    contentType : false,
                    success : function(){},
                    error   : function(){}
                });
            }
        });
    }
}