const APP = {
	name			: 'by design lab',
	html			: $('html'),
	body			: $('body'),
	websiteWrapper	: $('.js-website-wrapper'),
	websiteHeader	: $('.js-website-header'),
	desktopWidth	: 1366,
	desktopXsWidth  : 1199,
	tabletWidth     : 991,
	tabletXsWidth   : 767,
	mobileWidth     : 599,
	mobileXsWidth   : 359,
	hiddenCarousel  : 'carousel--hidden',
	inputPhone	 	: $('.js-input-phone'),
	modalTrigger 	: $('.js-modal-trigger'),
	modalActiveClass: 'fancybox-active',
};
const setWidth = () => {
    APP.websiteWrapper.css({
        'max-width' : window.deviceWidth + 'px'
    });
}
const unsetWidth = () => {
    APP.websiteWrapper.removeAttr('style');
}

const lazyLoad = () => {
    document.addEventListener('lazybeforeunveil', function(e){
        const src = e.target.getAttribute('data-image-src');
        if ( src ) {
            e.target.style.backgroundImage = 'url(' + src + ')';
        }
    });
}
const preventPageScroll = () => {
    const block = $('.js-scroll-prevent, .select .jq-selectbox__dropdown');

    block.on('mousewheel DOMMouseScroll', function (e) {
        const e0 = e.originalEvent;
        const delta = e0.wheelDelta || -e0.detail;
        this.scrollTop += ( delta < 0 ? 1 : -1 ) * 30;
        e.preventDefault();
    });
}
const formValidator = () => {

    const app = $('.js-form');
    window.isValidPhone = false;

    if ( app.length ) {
        $.validator.setDefaults({
            debug   : true,
            success : 'valid'
        });
        $.validator.addMethod("validateEmail", function(value, element) {
            if (/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(value)) {
                return true;
            } else {
                return false;
            }
        });
        $.validator.addMethod("validatePhone", function (value, element) {
            return value.replace(/\D+/g, '').length > 10;
            //return window.isValidPhone;
        });
        $.validator.addMethod("validateBirthDate", function (value, element) {
            return value.replace(/\D+/g, '').length === 8;
        });
        $.validator.addMethod("validatePassport", function (value, element) {
            return value.replace(/\D+/g, '').length === 10; // spaces included
        });
        $.validator.addMethod("validateCyrillic", function (value, element) {
            if (/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ]+$/.test(value)) {
                return true
            }
        });
        $.validator.addMethod("validateDigits", function (value, element) {
            if ( /^\d+$/.test(value) ) {
                return true
            }
        });
        $.validator.addMethod("validateBankBIK", function (value, element) {
            if ( /^\d+$/.test(value) && value.replace(/\D+/g, '').length === 9 ) {
                return true
            }
        });
        $.validator.addMethod("validateBankINN", function (value, element) {
            if ( /^\d+$/.test(value) && value.replace(/\D+/g, '').length === 10 ) {
                return true
            }
        });
        $.validator.addMethod("validateBankKPP", function (value, element) {
            if ( /^\d+$/.test(value) && value.replace(/\D+/g, '').length === 9 ) {
                return true
            }
        });
        $.validator.addMethod("validateBankKS", function (value, element) {
            if ( /^\d+$/.test(value) && value.replace(/\D+/g, '').length === 20 ) {
                return true
            }
        });

        app.each(function(){
            $(this).validate({
                errorClass   : "input--error",
                validClass   : "input--success",
                errorElement : "span",
                rules : {
                    Name : {
                        required : true,
                        validateCyrillic : true,
                    },
                    LastName : {
                        required : true,
                        validateCyrillic : true,
                    },
                    Patronymic : {
                        required : true,
                        validateCyrillic : true,
                    },
                    f_Phone : {
                        required      : true,
                        validatePhone : true
                    },
                    f_PhoneAlt : {
                        required      : true,
                        validatePhone : true
                    },
                    f_Email : {
                        required      : true,
                        validateEmail : true
                    },
                    f_Comment : {
                        required      : true
                    },
                    f_BirthDate  : {
                        required      : true,
                        validateBirthDate : true
                    },
                    birthDate : {
                        required      : true
                    },
                    birthMonth : {
                        required      : true
                    },
                    birthYear : {
                        required      : true
                    },
                    PaymentSum : {
                        required      : true
                    },
                    f_Agreement : {
                        required      : true
                    },
                    Passport : {
                        required         : true,
                        validatePassport : true
                    },
                    CompanyTitle : {
                        required : true,
                    },
                    CompanyName : {
                        required : true,
                        validateCyrillic : true,
                    },
                    CompanyPosition : {
                        required : true,
                    },
                    CompanyReasons : {
                        required : true,
                    },
                    CompanyINN : {
                        required : true,
                        validateBankINN : true
                    },
                    CompanyKPP : {
                        required : true,
                        validateBankKPP : true
                    },
                    CompanyLegalAddress : {
                        required : true,
                    },
                    CompanyFactAddress : {
                        required : true,
                    },
                    CompanyBankAccount : {
                        required : true,
                        validateDigits : true
                    },
                    CompanyBankTitle : {
                        required : true,
                    },
                    CompanyBankBIK : {
                        required : true,
                        validateBankBIK : true
                    },
                    CompanyBankKS : {
                        required : true,
                        validateBankKS : true
                    },
                    CompanyContactName : {
                        required : true,
                        validateCyrillic : true,
                    },
                    CompanyContactPhone : {
                        required : true,
                        validatePhone : true
                    },
                    CompanyContactEmail : {
                        required : true,
                        validateEmail : true
                    },
                },
                messages : {
                    Name           : 'Пожалуйста, напишите имя кириллицей',
                    LastName       : 'Пожалуйста, напишите фамилию кириллицей',
                    Patronymic     : 'Пожалуйста, напишите отчество кириллицей',
                    f_Phone        : 'Проверьте телефон',
                    f_PhoneAlt     : 'Проверьте телефон',
                    f_Email        : 'Проверьте электронную почту',
                    f_Comment      : 'Поле не заполнено',
                    f_BirthDate    : 'Поле не заполнено',
                    birthDate      : 'Поле не заполнено',
                    birthMonth     : 'Поле не заполнено',
                    birthYear      : 'Поле не заполнено',
                    PaymentSum     : 'Поле не заполнено!',
                    f_Agreement    : '',
                    Passport       : 'Поле не заполнено',
                    CompanyTitle    : 'Поле не заполнено',
                    CompanyName     : 'Пожалуйста, напишите Ф.И.О. кириллицей',
                    CompanyPosition : 'Поле не заполнено',
                    CompanyReasons  : 'Поле не заполнено',
                    CompanyINN      : 'Пожалуйста, проверьте ИНН банка',
                    CompanyKPP      : 'Пожалуйста, проверьте КПП банка',
                    CompanyLegalAddress : 'Поле не заполнено',
                    CompanyFactAddress  : 'Поле не заполнено',
                    CompanyBankAccount  : 'Поле не заполнено',
                    CompanyBankTitle    : 'Поле не заполнено',
                    CompanyBankBIK      : 'Пожалуйста, проверьте БИК банка',
                    CompanyBankKS       : 'Пожалуйста, проверьте К/с банка',
                    CompanyContactName  : 'Пожалуйста, напишите Ф.И.О. кириллицей',
                    CompanyContactPhone : 'Проверьте телефон',
                    CompanyContactEmail : 'Проверьте электронную почту',
                },

                errorPlacement: function(error, element) {
                    const parent = $(element).parents('.js-input, .js-custom-input');
                    $(error).appendTo(parent);
                },
                highlight: function(element, errorClass, validClass) {
                    $(element).parents('.js-input, .js-custom-input').addClass(errorClass).removeClass(validClass);
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parents('.js-input, .js-custom-input').removeClass(errorClass).addClass(validClass);
                },
                onfocusout: function(element) {
                    if (!this.checkable(element) && (element.name in this.submitted || !this.optional(element))) {
                        this.element(element);
                    }
                },
                submitHandler: function(form) {
                    const App       = $(form);
                    const action    = App.attr('action');

                    $.ajax({
                        url         : action,
                        type        : 'POST',
                        processData : false,
                        contentType : false,
                        success : function(){},
                        error   : function(){}
                    });
                }
            });
        });
    }
}
const profileFormValidator = () => {

    const app           = $('.js-profile-form');
    const wrapper       = '.js-form-wrapper';
    const invalidClass  = 'form-wrapper--invalid';

    if ( app.length ) {
        $.validator.setDefaults({
            debug   : true,
            success : 'valid'
        });
        $.validator.addMethod("validateBirthDate", function (value, element) {
            return value.replace(/\D+/g, '').length === 8;
        });
        $.validator.addMethod("validateCyrillic", function (value, element) {
            if (/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ]+$/.test(value)) {
                return true
            }
        });

        app.validate({
            errorClass   : "form-wrapper--invalid",
            validClass   : "form-wrapper--valid",
            errorElement : "span",
            groups: {
                profileData: 'profileData',
                identityGroup: 'LastName f_BirthDate'
            },
            rules : {
                LastName : {
                    required : true,
                    validateCyrillic : true,
                    require_from_group: [2, '.js-input-group']
                },
                f_BirthDate  : {
                    required: true,
                    validateBirthDate: true,
                    require_from_group: [2, '.js-input-group']
                },
            },
            messages : {
                LastName     : 'Неверные данные, пожалуйста, попробуйте еще раз',
                f_BirthDate  : '',
            },
            errorPlacement: function(error, element) {
                const parent = $(element).parents(wrapper);
                if ( $(element).hasClass('js-input-group') && !$('span.form-wrapper--invalid').length ) {
                    $(error).appendTo(parent);
                }
            },
            highlight: function(element, errorClass, validClass) {
                $(element).parents(wrapper).addClass(errorClass).removeClass(validClass);
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).parents(wrapper).removeClass(errorClass).addClass(validClass);
            },
            onfocusout: function(element) {
                if (!this.checkable(element) && (element.name in this.submitted || !this.optional(element))) {
                    this.element(element);
                }
            },
            submitHandler: function(form) {
                const App       = $(form);
                const action    = App.attr('action');

                $.ajax({
                    url         : action,
                    type        : 'POST',
                    processData : false,
                    contentType : false,
                    success : function(){},
                    error   : function(){}
                });
            }
        });
    }
}
const inputTel = (id) => {
    if ( id.length ) {
        id.each(function(){
            const $this = $(this);

            $this.inputmask({
                mask            : '[+]9 999 999-99-99',
                showMaskOnHover : false,
                onKeyValidation: function (key, result) {
                    if ( result.pos === 0 ) {
                        if ( key === 43 || key === 55 || key === 56 ) {
                            $this.val('+7');
                        }
                        else if ( key === 57 ) {
                            $this.val('+7 9');
                        }
                        else {
                            $this.val('');
                        }
                    }
                    if ( result.pos === 1 ) {
                        if ( key === 57 ) {
                            $this.val('+7 9');
                        }
                        else if ( key != 55 ) {
                            $this.val('+7');
                        }
                    }
                },
                onKeyDown: function ( event, buffer, caretPos, opts ) {
                    if ( caretPos.end === 2 && event.keyCode === 8 ) {
                        $this.val('');
                    }
                }
            });
        });
    }
}
const inputCurrence = () => {
    const PARAMS = {
        input : '.js-input-currency'
    };

    $(document).on('keyup', PARAMS.input, function(){
        prettify($(this));
    });
    function prettify(input){
        input.val(String(input.val().replace(/[^0-9.]/g,'')).replace(/\B(?=(\d{3})+(?!\d))/g, " "));
    }
}

const inputDate = () => {
    const PARAMS = {
        input : $('.js-input-date')
    };

    if ( PARAMS.input.length ) {
        PARAMS.input.inputmask('date', {
            mask            : '99.99.9999',
            placeholder     : "дд.мм.гггг",
            showMaskOnHover : false,
        });
    }
}
const inputEmail = () => {
    const PARAMS = {
        input : $('.js-input-email')
    };

    if ( PARAMS.input.length ) {
        PARAMS.input.each(function(){
            $(this).inputmask('email', {
                showMaskOnHover : false
            });
        });
    }
}
//example
//robin
//https://habr.com/ru/post/162537/
const autoPhoneMask = () => {
    //window.isValidPhone = false;
    const PARAMS = {
        input    : '.js-input-auto-phone',
        maskOpts : {
            inputmask : {
                showMaskOnHover : false,
                autoUnmask      : true,
                definitions: {
                    '#': {
                        validator: "[0-9]",
                        cardinality: 1
                    }
                },
            },
            match   : /[0-9]/,
            replace : '#',
            list    : $.masksSort($.masksLoad("./assets/phone-codes-world.json"), ['#'], /[0-9]|#/, "mask"),
            listKey : "mask",
            onMaskChange: function(maskObj, completed) {
                const parent = $(this).parents('.js-custom-input');
                const country = parent.find('.js-country');
                let hint;

                if (completed) {
                    hint = maskObj.name_ru;
                    if (maskObj.desc_ru && maskObj.desc_ru != "") {
                        hint += " (" + maskObj.desc_ru + ")";
                    }
                    $(this).attr("placeholder", $(this).inputmask("getemptymask"));
                    country.html(hint);
                    //window.isValidPhone = true;
                }
                else {
                    country.html('');
                    //window.isValidPhone = false;
                    $(this).attr("placeholder", '');
                }
            }
        },
    };
    $(PARAMS.input).inputmasks(PARAMS.maskOpts);
}
const inputPassport = () => {
    const PARAMS = {
        input : $('.js-input-passport')
    };

    if ( PARAMS.input.length ) {
        PARAMS.input.inputmask('date', {
            mask            : '9999 999999',
            placeholder     : "____ ______",
            showMaskOnHover : false,
        });
    }
}
const newsTextHandler = () => {
    const PARAMS = {
        page          : '.js-news-module',
        preview       : '.js-news-page-preview',
        full          : '.js-news-module-description',
        more          : '.js-news-module-more',
        expandedClass : 'news-page__preview--expanded',
        hiddenClass   : 'news-module__description--hidden'
    };

    $(document).on('click', PARAMS.more, function(){
        const parent = $(this).parents(PARAMS.page);
        const full   = parent.find(PARAMS.full);
        const preview = parent.find(PARAMS.preview);

        if ( full.hasClass(PARAMS.hiddenClass) ) {
            $(this).hide();
            full.removeClass(PARAMS.hiddenClass);
            preview.addClass(PARAMS.expandedClass);
        }
    });
};
const togglePaymentProps = () => {

    const PARAMS = {
        control      : '.js-payment-props-control',
        props        : $('.js-payment-props'),
        button       : $('.js-payment-button'),
        activeClass  : 'toggle-props',
        hiddenClass  : 'payment-props--hidden',
        changedClass : 'button--changed'
    };

    $(document).on('change', PARAMS.control, function(){
        if ( $(this).prop('checked') && $(this).hasClass(PARAMS.activeClass) ) {
            PARAMS.props.removeClass(PARAMS.hiddenClass);
            PARAMS.button.addClass(PARAMS.changedClass);
        }
        else {
            PARAMS.props.addClass(PARAMS.hiddenClass);
            PARAMS.button.removeClass(PARAMS.changedClass);
        }
    });
}
const toggleControlText = () => {
    const PARAMS = {
        control : '.js-payment-control',
        button  : '.js-payment-button',
        changeText(id) {
            $(document).on('click', id, function(){
                const text = $(this).attr('data-control-text');
                $(PARAMS.button).text(text);
            });
        }
    };

    PARAMS.changeText(PARAMS.control);
}
const OSDetect = () => {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac-OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
    } else if (/Android/.test(userAgent)) {
        os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
    }

    APP.html.addClass(os);
}
const stickyBar = (id, className) => {
    if ( !$(id).length ) return false;

    const PARAMS = {
        element       : $(id),
        offset        : $(id).offset().top,
        height        : $(id).height(),
        stickyClass   : className,
    };

    $(window).on('scroll', function(){
        if( $(window).scrollTop() > PARAMS.offset + PARAMS.height ){
            APP.websiteWrapper.addClass(PARAMS.stickyClass);
        }
        else {
            APP.websiteWrapper.removeClass(PARAMS.stickyClass);
        }
    });
};
const toggleHiddenRow = () => {
    const PARAMS = {
        parent      : '.js-form-section',
        input       : '.js-toggle-hidden-row',
        row         : '.js-form-row',
        hiddenClass : 'form__row--hidden'
    };

    $(document).on('click', PARAMS.input, function(){
        const parent = $(this).parents(PARAMS.parent);
        const row    = parent.find(PARAMS.row);

        if ( $(this).prop('checked') ) {
            row.removeClass(PARAMS.hiddenClass);
        }
        else {
            row.addClass(PARAMS.hiddenClass);
        }
    });
}
const multilevelAccordion = () => {
    const PARAMS = {
        accordion     : '.js-ml-accordion',
        button        : '.js-ml-accordion-button',
        body          : '.js-ml-accordion-body',
        expandedClass : '_expanded',
        hiddenClass   : '_hide',
        collapseLevel(level) {
            const lvl = $(`[data-level="${level}"]`);
            const lvls = lvl.find(PARAMS.accordion);
            const body = lvl.find(PARAMS.body);

            lvl.removeClass(PARAMS.expandedClass);
            lvls.removeClass(PARAMS.expandedClass);
            body.stop().slideUp(300);
        },
        toggleLevel(element) {
            const parent = element.closest(PARAMS.accordion);
            const id     = element.attr('data-accordion-id');
            const level  = element.attr('data-level-collapser');
            const body   = $(`[data-accordion-level="${id}"]`);

            if ( !parent.hasClass(PARAMS.expandedClass) ) {
                //collapse all other accordions of the same level
                PARAMS.collapseLevel(level);

                parent.addClass(PARAMS.expandedClass);
                body.stop().slideDown(300);
            }
            else {
                parent.removeClass(PARAMS.expandedClass);
                body.stop().slideUp(300);
            }
        }
    };

    $(PARAMS.accordion).each(function(){
        if (!$(this).hasClass(PARAMS.expandedClass)) {
            $(this).find(PARAMS.body).removeClass(PARAMS.hiddenClass).hide();
        }
        else {
            $(this).find(PARAMS.body).removeClass(PARAMS.hiddenClass);
        }
    });
    $(document).on('click', PARAMS.button, function(){
        PARAMS.toggleLevel($(this));
    });
};
const eventsFormHandler = () => {
    const PARAMS = {
        form        : '.js-events-page-form',
        body        : '.js-events-page-form-body',
        button      : '.js-events-page-form-button',
        activeClass : '_active',

        collapseHandler(element){
            element.stop().slideUp(300);
        },
        expandHandler(element){
            element.stop().slideDown(300);
        },
        textHandler(element, text){
            element.text(text);
        },
        handler(element){
            const parent = element.parents(PARAMS.form);
            const body   = parent.find(PARAMS.body);
            const collapsedText = element.attr('data-collapsed-text');
            const expandedText  = element.attr('data-expanded-text');

            if ( !parent.hasClass(PARAMS.activeClass) ) {
                parent.addClass(PARAMS.activeClass);
                PARAMS.expandHandler(body);
                PARAMS.textHandler(element, expandedText);
            }
            else {
                parent.removeClass(PARAMS.activeClass);
                PARAMS.collapseHandler(body);
                PARAMS.textHandler(element, collapsedText);
            }
        }
    };

    $(document).on('click', PARAMS.button, function(){
        PARAMS.handler($(this));
    });
};

const selectStyler = () => {



    const PARAMS = {

        select    : 'select',

        parent    : '.select',

        initClass : 'select--initialized'

    };



    if ($(PARAMS.parent).length) {

        $(PARAMS.parent).each(function(){

            const $this = $(this);

            const select = $this.find(PARAMS.select);



            if ( !$this.hasClass(PARAMS.initClass) ) {

                select.styler({

                    selectSmartPositioning : false,

                    selectSearch           : false,

                    onFormStyled: function() {

                        $this.addClass(PARAMS.initClass);

                    }

                });

            }

            else {

                select.styler('refresh');

            }

        });



        $(document).on('click', '.select', function(){

            const dropdown = $(this).find('ul');

            if ( !dropdown.hasClass('ps') ) {

                const customScrollBar = new CustomScrollbar(dropdown);

            }

        });

    }



}


const navigation = () => {

    const PARAMS = {
        link          : '.js-navigation-levels-link',
        row           : '.js-navigation-levels-row',
        columnLink    : '.js-navigation-levels-column-link',
        columnArray   : '.js-navigation-levels-column-array',
        activeClass   : '_active',
        expandedClass : '_expanded',
    };

    //expand 2nd level
    $(document).on('click', PARAMS.link, function(e){
        e.preventDefault();

        const id = $(this).attr('data-id');
        const level = $('[data-level-id="' + id + '"]');

        if ( !level.hasClass(PARAMS.expandedClass) ) {
            //hide all rows & remove active class form link
            $(PARAMS.row).removeClass(PARAMS.expandedClass);
            $(PARAMS.link).removeClass(PARAMS.activeClass);

            //show exact row & add active class to link
            $(this).addClass(PARAMS.activeClass);
            level.addClass(PARAMS.expandedClass);
        }
        else {
            $(this).removeClass(PARAMS.activeClass);
            level.removeClass(PARAMS.expandedClass);
        }
    });
    //expand & enable links in 3rd level
    $(document).on('click', PARAMS.columnLink, function(e){
        e.preventDefault();

        const array = $(this).parents(PARAMS.row).find(PARAMS.columnArray);
        const link  = $(this).parents(PARAMS.row).find(PARAMS.columnLink);

        link.removeClass('js-navigation-levels-column-link');
        array.addClass(PARAMS.activeClass);
    });
}

const websiteSearch = () => {

    const PARAMS = {
        button        : $('.js-website-search-button'),
        search        : $('.js-website-search'),
        input         : $('.js-website-search-input'),
        activeClass   : 'website-wrapper--search-expanded',
        notEmptyClass : 'website-search--found-results',
    };

    PARAMS.button.on('click', function(){
        if ( !APP.websiteWrapper.hasClass(PARAMS.activeClass) ) {
            APP.websiteWrapper.stop().addClass(PARAMS.activeClass);
            PARAMS.search.stop().slideDown(300);

            closeModalHandler();
        }
        else {
            APP.websiteWrapper.stop().removeClass(PARAMS.activeClass);
            PARAMS.search.stop().slideUp(300);
        }
    });
    PARAMS.input.on('keyup', function(){
       if ( $(this).val() != '' ) {
           PARAMS.search.addClass(PARAMS.notEmptyClass);
       }
       else {
           PARAMS.search.removeClass(PARAMS.notEmptyClass);
       }
    });

    $(document).on('mouseup touchend', function(e){
        if ( !PARAMS.search.is(e.target) && PARAMS.search.has(e.target).length === 0 ) {
            if ( PARAMS.search.hasClass(PARAMS.notEmptyClass) ) {
                PARAMS.search.removeClass(PARAMS.notEmptyClass);
            }
        }
    });
};

const heroSlider = () => {

    const PARAMS = {
        slider   : $('.js-hero-slider')
    };

    if ( PARAMS.slider.length ) {

        PARAMS.slider.each(function(){
            const $this = $(this);
            const quantity  = $this.find('.hero-slider__element').length;
            const autoPlay  = ( typeof $('.js-hero-slider').attr('data-autoplay') !== 'undefined' ? parseInt($('.js-hero-slider').attr('data-autoplay')) : 0 );
            const autoSpeed = ( typeof $('.js-hero-slider').attr('data-auto-speed') !== 'undefined' ? parseInt($('.js-hero-slider').attr('data-auto-speed')) : 5000 );
            const speed     = ( typeof $('.js-hero-slider').attr('data-speed') !== 'undefined' ? parseInt($('.js-hero-slider').attr('data-speed')) : 300 );

            if ( quantity > 1 ) {
                $this.on('init reInit afterChange', function(event, slick, currentSlide){
                    $this.removeClass(APP.hiddenCarousel);
                });
                $this.slick({
                    autoplay        : autoPlay,
                    autoplaySpeed   : autoSpeed,
                    speed           : speed,
                    slidesToShow    : 1,
                    arrows          : true,
                    prevArrow       : '<button type="button" class="slick-prev"></button>',
                    nextArrow       : '<button type="button" class="slick-next"></button>',
                    dots            : true,
                    infinite        : true,
                    cssEase         : 'linear',
                    touchThreshold  : 15,
                    responsive      : [
                        {
                            breakpoint: 767,
                            settings: {}
                        }
                    ]
                });
            }
        });
    }
}

const autoWidthCarousel = () => {

    const PARAMS = {
        carousel : $('.js-auto-width-carousel')
    };

    if ( PARAMS.carousel.length ) {
        PARAMS.carousel.each(function(){
            const $this = $(this);
            $this.on('init reInit afterChange', function(event, slick, currentSlide){
                $this.removeClass(APP.hiddenCarousel);
            });

            $this.slick({
                variableWidth   : true,
                slidesToShow    : 4,
                arrows          : true,
                prevArrow       : '<button type="button" class="slick-prev"></button>',
                nextArrow       : '<button type="button" class="slick-next"></button>',
                dots            : false,
                infinite        : false,
                touchThreshold  : 15,
                responsive      : [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow : 3
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow : 2
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            //arrows : false
                        }
                    },
                    {
                        breakpoint: 599,
                        settings: {
                            slidesToShow : 2,
                            //arrows       : false
                        }
                    }
                ]
            });
        });
    }

}

const mobileNavigation = () => {

    const BAR = {
        button      : '.js-mobile-nav-button',
        body        : $('.js-mobile-sidebar'),
        inside      : $('.js-mobile-sidebar-body'),
        activeClass : '_nav-opened',
    };

    const NAV = {
        body         : $('.js-mobile-navigation'),
        level        : '.js-mobile-navigation-level',
        levelChanger : '.js-mobile-navigation-level-changer',
        element      : '.js-mobile-navigation-element',
        linkBack     : '.js-mobile-navigation-link-back',
        activeClass  : '_active',
        arrow        : '<span class="mobile-navigation__link-arrow"><svg class="i" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.66656 1.19672L6.43953 5.96969C6.73242 6.26259 6.73242 6.73746 6.43953 7.03035L1.66656 11.8033C1.37367 12.0962 0.898794 12.0962 0.605901 11.8033C0.313007 11.5104 0.313007 11.0356 0.605901 10.7427L4.84854 6.50002L0.605901 2.25738C0.313007 1.96449 0.313007 1.48961 0.605901 1.19672C0.898794 0.903827 1.37367 0.903827 1.66656 1.19672Z"/></svg></span>',
        defaultOffset: $('.js-mobile-navigation').offset().top
    };

    let right = 0;

    $(document).on('click', BAR.button, function(){
        if ( !APP.body.hasClass(BAR.activeClass) ) {
            APP.body.addClass(BAR.activeClass);
        }
        else {
            right = 0;
            NAV.body.css('right', right * 100 + '%');
            $(NAV.level).removeClass(NAV.activeClass);
            APP.body.removeClass(BAR.activeClass);
        }

        closeModalHandler();
    });

    //add svg arrows to the links with sublevels
    for (let i = 0; i < $(NAV.element).length; i++) {
        const element = $(NAV.element).eq(i);

        if (element.children().length >= 2) {
            const linkContent = element.find('> .js-mobile-navigation-link').text();
            element.find('> .js-mobile-navigation-link').html(linkContent + NAV.arrow);
        }
    }

    //go to sublevel
    //const navigationLinkArrow = $('.js-mobile-navigation-link-arrow'); //go to sublevel on arrow click
    //const navigationLinkArrow = $('.js-mobile-navigation-link'); //got to sublevel on link click

    $(document).on('click', NAV.levelChanger, function(e) {
        e.preventDefault();
        const navOffset = NAV.body.offset().top;

        //scroll to the top of the level if nav is scrolled down
        if ( navOffset !== NAV.defaultOffset ) {
            scrollTopHandler();
        }

        if ( $(this).parents(NAV.element).children().length >= 2 ) {
            right++;
            $(this).parents(NAV.element).children(NAV.level).addClass(NAV.activeClass);
            NAV.body.css('right', right * 100 + '%');
            return false;
        }
    });

    //go back to previous level
    $(document).on('click', NAV.linkBack, function(e) {
        e.preventDefault();
        right--;
        NAV.body.css('right', right * 100 + '%');
        $(`${NAV.level}.${NAV.activeClass}`).last().removeClass(NAV.activeClass);
        return false;
    });

    const scrollTopHandler = () => {
        BAR.inside.animate({
            scrollTop: 0
        }, 300);
    }
}

const interactiveMap = () => {

    const PARAMS = {
        open        : '.js-interactive-map-link',
        close       : '.js-interactive-map-popup-close',
        popup       : '.js-interactive-map-popup',
        activeClass : '_active',
    };

    $(document).on('click', PARAMS.open, function(){
        const id    = $(this).attr('data-map-id');
        const popup = $('#'+id);

        $(PARAMS.popup).removeClass( PARAMS.activeClass );

        if ( !popup.hasClass( PARAMS.activeClass ) ) {
            popup.addClass( PARAMS.activeClass);
        }
    });

    $(document).on('click', PARAMS.close, function(){
        const parent = $(this).parents(PARAMS.popup);
        parent.removeClass( PARAMS.activeClass );
    });

}

const websiteViewportChanger = () => {

    const PARAMS = {
        viewport        : $('.js-meta-viewport'),
        trigger         : '.js-desktop-version-trigger',
        desktopViewport : 'width=1440, user-scalable=yes, viewport-fit=cover',
        mobileViewport  : 'width=device-width, height=device-height, initial-scale=1, shrink-to-fit=no, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0',
    };
    let desktopVersion = false;

    $(document).on('click', PARAMS.trigger, function(){
        if (desktopVersion) {
            desktopVersion = false;
            PARAMS.viewport.attr('content', PARAMS.mobileViewport);
        }
        else {
            desktopVersion = true;
            PARAMS.viewport.attr('content', PARAMS.desktopViewport);
        }
    });
}

const hiddenBlocksHandler = () => {

    const PARAMS = {
        parent      : '.js-hidden-blocks',
        button      : '.js-hidden-blocks-more',
        element     : '.js-hidden-blocks-element',
        activeClass : '_active',
        hiddenClass : 'hidden-blocks__element--hidden',
    };

    $(document).on('click', PARAMS.button, function(e){
        e.preventDefault();
        const elements  = $(this).parents(PARAMS.parent).find(PARAMS.element);
        const collapsed = $(this).attr('data-hb-collapsed');
        const expanded  = $(this).attr('data-hb-expanded');

        if ( !$(this).hasClass(PARAMS.activeClass) ) {
            $(this).text(expanded);
            $(this).addClass(PARAMS.activeClass);
            elements.removeClass(PARAMS.hiddenClass);
        }
        else {
            $(this).text(collapsed);
            $(this).removeClass(PARAMS.activeClass);
            elements.addClass(PARAMS.hiddenClass);
        }
    });

}

function CustomScrollbar(cont){
    this.cont = cont;
    this.scrollBars = [];
    this.init();
}
CustomScrollbar.prototype = {
    init: function(){
        const obj = this;
        if (this.cont.length > 0) {
            this.cont.each(function(){
                obj.scrollBars.push(new PerfectScrollbar(this));
            })
        }
    },
    update: function(){
        this.scrollBars.forEach(function(el){
            el.update();
        })
    }
}

const defaultSlider = () => {

    const PARAMS = {
        slider : $('.js-default-slider'),
    };

    if ( PARAMS.slider.length ) {
        PARAMS.slider.each(function(){
            const $this = $(this);
            $this.on('init reInit afterChange', function(event, slick, currentSlide){
                $this.removeClass(APP.hiddenCarousel);
            });

            $this.slick({
                slidesToShow    : 4,
                arrows          : true,
                prevArrow       : '<button type="button" class="slick-prev"></button>',
                nextArrow       : '<button type="button" class="slick-next"></button>',
                dots            : false,
                infinite        : false,
                touchThreshold  : 15,
                responsive      : [
                                        {
                        breakpoint: 991,
                        settings: {
                            slidesToShow : 3
                        }
                    },
                    {
                        breakpoint: 767,
                        settings: {
                            slidesToShow : 2,
                            //arrows : false,
                            dots   : true
                        }
                    },
                    {
                        breakpoint: 599,
                        settings: {
                            slidesToShow : 1,
                            //arrows       : false,
                            dots         : true
                        }
                    }
                ]
            });
        });
    }

}

const responsiveTabs = (id, type) => {

    if ( id.length ) {
        id.easyResponsiveTabs({
            type                        : type,
            tabidentify                 : '_responsive-tabs',
            activetab_bg                : 'transparent',
            inactive_bg                 : 'transparent',
            active_border_color         : 'transparent',
            active_content_border_color : 'transparent',
            activate: function () {
                //scroll to opened tab after previous was closed
                if ( window.deviceWidth <= APP.tabletXsWidth ) {
                    const $this = $(this);

                    setTimeout(function(){
                        const offset = $this.offset().top - APP.websiteHeader.height();
                        $('body, html').animate({
                            scrollTop: offset
                        }, 600);
                    }, 400);
                }
            }
        });
    }
}

const modal = () => {
    $('[data-fancybox]').fancybox({
        autoFocus: false,
        youtube : {
            controls : 0,
            showinfo : 0
        }
    });
}
const closeModalHandler = () => {
    if ( APP.body.hasClass(APP.modalActiveClass) ) {
        $.fancybox.close();
    }
}

const mobileSlider = (width) => {

    const slider = $('.js-mobile-slider');

    if ( slider.length) {
        if ( window.deviceWidth <= width ) {
            slider.each(function(){
                let $this = $(this);
                if ( !$this.hasClass('slick-initialized') ) {
                    $this.slick({
                        infinite        : false,
                        slidesToShow    : 1,
                        slidesToScroll  : 1,
                        slide           : '.mobile-slider__element',
                        arrows          : true,
                        prevArrow       : '<button type="button" class="slick-prev"></button>',
                        nextArrow       : '<button type="button" class="slick-next"></button>',
                        dots            : true,
                    });
                }
                else {
                    $this.slick('refresh');
                }
            });
        }
        else {
            slider.each(function(){
                let $this = $(this);
                if ( $this.hasClass('slick-initialized') ) {
                    $this.slick('unslick');
                }
            });
        }
    }
}

const accordion = (container, collapsible) => {

    if ( $(container).length ) {
        const ACC = {
            parent        : '.js-accordion',
            button        : '.js-accordion-button',
            body          : '.js-accordion-body',
            hiddenClass   : '_hide',
            expandedClass : '_expanded'
        };

        $(container).find(ACC.parent).each(function(){
            const button = $(this).find(ACC.button);

            if (!$(this).hasClass(ACC.expandedClass)) {
                $(this).find(ACC.body).removeClass(ACC.hiddenClass).hide();
            }
            else {
                $(this).find(ACC.body).removeClass(ACC.hiddenClass);
            }

            button.on('click', function(){
                const accordion      = $(this).parents(ACC.parent);
                const $container     = accordion.parents(container);
                const $accordionBody = accordion.find(ACC.body);

                if ( !accordion.hasClass(ACC.expandedClass) ) {
                    if (collapsible) {
                        $container.find(ACC.parent).removeClass(ACC.expandedClass);
                        $container.find(ACC.body).stop().slideUp(300);
                    }
                    accordion.addClass(ACC.expandedClass);
                    $accordionBody.stop().slideDown(300);
                }
                else {
                    accordion.removeClass(ACC.expandedClass);
                    accordion.find(ACC.body).stop().slideUp(300);
                }

                //scroll to top of opened accordion on mobile devices
                if ( collapsible && window.deviceWidth <= APP.tabletXsWidth ) {
                    setTimeout(function(){
                        const offset = accordion.offset().top - APP.websiteHeader.height() - 10;
                        $('html, body').animate({ scrollTop: offset }, '200');
                    }, 400);
                }
            });
        });
    }
};

const customInput = () => {
    const PARAMS = {
        parent       : '.js-custom-input',
        input        : '.js-custom-input-input',
        focusClass   : 'custom-input--focused',
        emptyClass   : 'custom-input--not-empty',
        errorClass   : 'custom-input--error',
        successClass : 'custom-input--success',
    };

    $(PARAMS.input).on('keyup focusin focusout', function(e){
        const parent = $(this).parents(PARAMS.parent);

        if ( $(this).val() != '' ) {
            parent.addClass(PARAMS.emptyClass);
        } else {
            parent.removeClass(PARAMS.emptyClass);
        }

        if ( e.type === 'focusin' ) {
            parent.addClass(PARAMS.focusClass);
        }
        else if ( e.type === 'focusout' ) {
            parent.removeClass(PARAMS.focusClass);
        }
    });
};

const videoPlayer = () => {

    const link = $('.js-video-player-link');

    link.on('click', function(){

        const parent         = this.closest('.js-video-player');
        const id             = $(this).attr('data-video-id');
        const videoContainer = parent.querySelector('.js-video-player-container');
        const activeClass    = 'video-player--playing';

        $(parent).addClass(activeClass);

        if ( $(videoContainer).find('iframe.js-video-player-container').length < 1 ) {
            let videoPlayer = new YT.Player(videoContainer, {
                height: '100%',
                width: '100%',
                videoId: id,
                playerVars: {
                    rel: 0,
                    controls: 1,
                    showinfo: 0,
                    autoplay: 1
                },
                events: {
                    'onStateChange': onPlayerStateChange
                }
            });
        }
        function onPlayerStateChange(e) {
            if(e.data === 0) {
                setTimeout(function(){
                    parent.removeClass(activeClass);
                    videoPlayer.destroy();
                }, 200);
            }
        }
    });

}


$(function() {

	console.log(APP.name);

    window.deviceWidth = APP.body.width();



    if ( $('.js-scrollable-block').length ) {

        window.scrollbars = new CustomScrollbar($(".js-scrollable-block"));

    }



    lazyLoad();

    heroSlider();

    autoWidthCarousel();

    websiteSearch();

    navigation();

    mobileNavigation(APP.tabletXsWidth);

    interactiveMap();

    websiteViewportChanger();

    selectStyler();

    hiddenBlocksHandler();

    preventPageScroll();

    responsiveTabs($('#trip-page-tabs'), 'default');

    defaultSlider();

    formValidator();

    profileFormValidator();

    inputTel(APP.inputPhone);

    inputCurrence();

    inputDate();

    inputEmail();

    inputPassport();

    modal();

    newsTextHandler();

    accordion('.js-accordions-collapsible', true);

    multilevelAccordion();

    togglePaymentProps();

    OSDetect();

    customInput();

    stickyBar('.js-trip-brief', 'website-wrapper--trips-brief-sticky');

    toggleControlText();

    toggleHiddenRow();

    videoPlayer();

    eventsFormHandler();

    autoPhoneMask();

});



$(window).on('load resize orientationchange', function(e){

    const updatedWidth = $(window).width();



    if ( e.type === 'load' ) {}

    if ( e.type === 'resize' || e.type === 'orientationchange' ) {

        if ( window.deviceWidth !== updatedWidth ) {

            window.deviceWidth = updatedWidth;

        }

    }

    mobileSlider(APP.tabletXsWidth);

});

$(window).on('scroll', function(){});