const inputCurrence = () => {
    const PARAMS = {
        input : '.js-input-currency'
    };

    $(document).on('keyup', PARAMS.input, function(){
        prettify($(this));
    });
    function prettify(input){
        input.val(String(input.val().replace(/[^0-9.]/g,'')).replace(/\B(?=(\d{3})+(?!\d))/g, " "));
    }
}
