const toggleHiddenRow = () => {
    const PARAMS = {
        parent      : '.js-form-section',
        input       : '.js-toggle-hidden-row',
        row         : '.js-form-row',
        hiddenClass : 'form__row--hidden'
    };

    $(document).on('click', PARAMS.input, function(){
        const parent = $(this).parents(PARAMS.parent);
        const row    = parent.find(PARAMS.row);

        if ( $(this).prop('checked') ) {
            row.removeClass(PARAMS.hiddenClass);
        }
        else {
            row.addClass(PARAMS.hiddenClass);
        }
    });
}