const inputTel = (id) => {
    if ( id.length ) {
        id.each(function(){
            const $this = $(this);

            $this.inputmask({
                mask            : '[+]9 999 999-99-99',
                showMaskOnHover : false,
                onKeyValidation: function (key, result) {
                    if ( result.pos === 0 ) {
                        if ( key === 43 || key === 55 || key === 56 ) {
                            $this.val('+7');
                        }
                        else if ( key === 57 ) {
                            $this.val('+7 9');
                        }
                        else {
                            $this.val('');
                        }
                    }
                    if ( result.pos === 1 ) {
                        if ( key === 57 ) {
                            $this.val('+7 9');
                        }
                        else if ( key != 55 ) {
                            $this.val('+7');
                        }
                    }
                },
                onKeyDown: function ( event, buffer, caretPos, opts ) {
                    if ( caretPos.end === 2 && event.keyCode === 8 ) {
                        $this.val('');
                    }
                }
            });
        });
    }
}