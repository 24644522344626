const togglePaymentProps = () => {

    const PARAMS = {
        control      : '.js-payment-props-control',
        props        : $('.js-payment-props'),
        button       : $('.js-payment-button'),
        activeClass  : 'toggle-props',
        hiddenClass  : 'payment-props--hidden',
        changedClass : 'button--changed'
    };

    $(document).on('change', PARAMS.control, function(){
        if ( $(this).prop('checked') && $(this).hasClass(PARAMS.activeClass) ) {
            PARAMS.props.removeClass(PARAMS.hiddenClass);
            PARAMS.button.addClass(PARAMS.changedClass);
        }
        else {
            PARAMS.props.addClass(PARAMS.hiddenClass);
            PARAMS.button.removeClass(PARAMS.changedClass);
        }
    });
}