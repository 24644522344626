const inputPassport = () => {
    const PARAMS = {
        input : $('.js-input-passport')
    };

    if ( PARAMS.input.length ) {
        PARAMS.input.inputmask('date', {
            mask            : '9999 999999',
            placeholder     : "____ ______",
            showMaskOnHover : false,
        });
    }
}