const APP = {
	name			: 'by design lab',
	html			: $('html'),
	body			: $('body'),
	websiteWrapper	: $('.js-website-wrapper'),
	websiteHeader	: $('.js-website-header'),
	desktopWidth	: 1366,
	desktopXsWidth  : 1199,
	tabletWidth     : 991,
	tabletXsWidth   : 767,
	mobileWidth     : 599,
	mobileXsWidth   : 359,
	hiddenCarousel  : 'carousel--hidden',
	inputPhone	 	: $('.js-input-phone'),
	modalTrigger 	: $('.js-modal-trigger'),
	modalActiveClass: 'fancybox-active',
};