const newsTextHandler = () => {
    const PARAMS = {
        page          : '.js-news-module',
        preview       : '.js-news-page-preview',
        full          : '.js-news-module-description',
        more          : '.js-news-module-more',
        expandedClass : 'news-page__preview--expanded',
        hiddenClass   : 'news-module__description--hidden'
    };

    $(document).on('click', PARAMS.more, function(){
        const parent = $(this).parents(PARAMS.page);
        const full   = parent.find(PARAMS.full);
        const preview = parent.find(PARAMS.preview);

        if ( full.hasClass(PARAMS.hiddenClass) ) {
            $(this).hide();
            full.removeClass(PARAMS.hiddenClass);
            preview.addClass(PARAMS.expandedClass);
        }
    });
};