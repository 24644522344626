const eventsFormHandler = () => {
    const PARAMS = {
        form        : '.js-events-page-form',
        body        : '.js-events-page-form-body',
        button      : '.js-events-page-form-button',
        activeClass : '_active',

        collapseHandler(element){
            element.stop().slideUp(300);
        },
        expandHandler(element){
            element.stop().slideDown(300);
        },
        textHandler(element, text){
            element.text(text);
        },
        handler(element){
            const parent = element.parents(PARAMS.form);
            const body   = parent.find(PARAMS.body);
            const collapsedText = element.attr('data-collapsed-text');
            const expandedText  = element.attr('data-expanded-text');

            if ( !parent.hasClass(PARAMS.activeClass) ) {
                parent.addClass(PARAMS.activeClass);
                PARAMS.expandHandler(body);
                PARAMS.textHandler(element, expandedText);
            }
            else {
                parent.removeClass(PARAMS.activeClass);
                PARAMS.collapseHandler(body);
                PARAMS.textHandler(element, collapsedText);
            }
        }
    };

    $(document).on('click', PARAMS.button, function(){
        PARAMS.handler($(this));
    });
};