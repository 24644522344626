const toggleControlText = () => {
    const PARAMS = {
        control : '.js-payment-control',
        button  : '.js-payment-button',
        changeText(id) {
            $(document).on('click', id, function(){
                const text = $(this).attr('data-control-text');
                $(PARAMS.button).text(text);
            });
        }
    };

    PARAMS.changeText(PARAMS.control);
}